<nb-spinner *ngIf="isSaving" class="opslaan-spinner" size="giant" [message]="'Opslaan..'" status="info"></nb-spinner>
<div style="display:flex;flex-direction: row;justify-content: flex-start;align-items: center; padding-bottom: 7px">
  <h1 class="custom-title"><i class="fa-solid fa-calendar-check bigFaIcon"></i>Werfplanning/personeelstoewijzing</h1>
  <div *ngIf="isViewingDay">
    <h2 class="dagPlanningTitle">Dagplanning: {{convertDate(selectedDate)}}</h2>
  </div>
  <div  class="shorterFlex">
    <p class="sortText">Datum</p>
    <div class="colFlex">
      <nb-select [disabled]="isViewingDay" class="bigSelect" (selectedChange)="checkMaand($event)" [ngModel]="maand">
        <nb-option *ngFor="let maand of maanden" value="{{ maand }}">{{ maand }}
        </nb-option>
      </nb-select>
      <nb-select [disabled]="isViewingDay" class="jaarSelect" (selectedChange)="checkJaar($event)" [ngModel]="jaar">
        <nb-option *ngFor="let jaar of jaren" value="{{ jaar }}">{{ jaar }}
        </nb-option>
      </nb-select>
    </div>
  </div>
  <button  mat-raised-button class="nav-button-black" (click)="goToEditPloegen()">Ploegen instellen</button>
  <button *ngIf="isViewingDay" mat-raised-button class="nav-button-black" (click)="bekijk(null)">Sluiten</button>
</div>

<ng-container *ngIf="isLoaded">

  <div   *ngIf="!isViewingDay" class="calendar-header">
    <div class="day-header">Maandag</div>
    <div class="day-header">Dinsdag</div>
    <div class="day-header">Woensdag</div>
    <div class="day-header">Donderdag</div>
    <div class="day-header">Vrijdag</div>
  </div>
  <div  *ngIf="!isViewingDay" class="calendar-grid">
    <ng-container  *ngFor="let day of daysInMonth$ | async;">
      <div  (click)="bekijk(day)" *ngIf="!day.isEmpty; else emptyBlock"
            [ngClass]="day.soort !== 'vroegereDatum' && day.soort !== 'latereDatum' && day.soort !== 'feestdag' && day.soort !== 'verlofdag'? 'kanOpslaan': day.soort"
            class="day-container">
        <div class="topRowBox">
          <p class="datumText"><strong>{{day.date.getDate() + ' ' + maanden[day.date.getMonth()] }}</strong></p>
        </div>
        <p class="soortText" >{{day.soort.toUpperCase()}}</p>
        <div *ngIf="day.day === 'Vrijdag'" class="break"></div>

      </div>
      <ng-template #emptyBlock><div  class="day-container empty"></div></ng-template>
    </ng-container>
  </div>
  <div class="outDivDayView" *ngIf="isViewingDay">
    <div class="container">

      <div cdkDropListGroup>
        <div class="static-section">
          <h3 class="header-beschikbare-medewerkers">
            <span class="title">Beschikbare arbeiders</span>
            <mat-button-toggle-group   [(ngModel)]="sortChoiceBeschikbareArbeiders" name="sorteerOpties" aria-label="Sorteer opties">
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="naam">Sorteer op naam</mat-button-toggle>
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="ploegen">Groeperen per ploeg/voorkeurarbeiders</mat-button-toggle>
              <mat-button-toggle (click)="changeSortChoiceBeschikbareArbeiders()" class="sorteerOpties" value="functie">Groeperen per functie</mat-button-toggle>
            </mat-button-toggle-group>
          </h3>

          <div *ngIf="sortChoiceBeschikbareArbeiders === 'naam'"
               class="example-list"
               cdkDropList
               [cdkDropListData]="unassignedArbeiders"
               (cdkDropListDropped)="dropArbeider($event)">
            <div class="example-box"
                 *ngFor="let arbeider of unassignedArbeiders"
                 cdkDrag
                 [cdkDragData]="arbeider"
                 (click)="toggleSelection(arbeider, $event)"
                 [class.selected]="isSelected(arbeider)">
              {{arbeider.name}}
            </div>
          </div>

          <div *ngIf="sortChoiceBeschikbareArbeiders === 'ploegen'"
               class="ploegen-list"
               cdkDropList
               [cdkDropListData]="ploegen"
               (cdkDropListDropped)="dropPloeg($event)">
            <div class="ploeg-box"
                 *ngFor="let ploeg of ploegen"
                 cdkDrag
                 [cdkDragData]="ploeg"
                 (click)="toggleSelection(ploeg, $event)"
                 [class.selected]="isSelected(ploeg)">
              {{ploeg.ploegbaas.name}} ({{ploeg.voorkeurArbeiders.length}} arbeiders)
            </div>
          </div>
        </div>

        <div class="scrollable-section">
          <div *ngFor="let werfplanning of werfPlanningen; let i = index">
            <div class="werfHeader">
              <span>{{werfplanning.werf.naam}}</span>
            </div>
            <div class="example-list werf-list"
                 cdkDropList
                 [cdkDropListData]="werfplanning.selectedArbeiders"
                 (cdkDropListDropped)="drop($event)">
              <div class="example-box inWerfLightBlueBackGround"
                   *ngFor="let arbeider of werfplanning.selectedArbeiders"
                   cdkDrag
                   [cdkDragData]="arbeider"
                   (click)="toggleSelection(arbeider, $event)"
                   [class.selected]="isSelected(arbeider)">
                {{arbeider.name}}
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  </div>

</ng-container>
