import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  NgZone,
  Input,
  Inject,
  Optional
} from '@angular/core';
import {UntypedFormBuilder, Validators, FormControl, FormArray} from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import {ApiService} from "../../../services/api.service";
import {Werf} from "../../../models/werf";
import {Company} from "../../../models/company";
import {User} from "../../../models/user";
import {finalize} from "rxjs";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {map, startWith} from "rxjs/operators";
import {FormService} from "../../../services/form.service";
import {Bedrijf} from "../../../models/E-digid/bedrijf";
import {Router} from "@angular/router";
import * as html2pdf from 'html2pdf.js';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ngx-klanten-add',
  templateUrl: './klanten-add.component.html',
  styleUrls: ['./klanten-add.component.scss', '../../../styles/add-form.scss']
})
export class KlantenAddComponent implements OnInit {
  @ViewChild('qrCodeElement') qrCodeElement: ElementRef;

  verwerkingstechnieken = this.formService.verwerkingstechnieken;
  D_en_R_codes = this.formService.D_en_R_codes;
  addForm;
  public isLoaded: boolean;
  isNameInvalid: boolean = false;
  @Output() outputEvent: EventEmitter<string> = new EventEmitter();
  company: Company;
  isSaving: boolean;
  isGettingVATnumber: boolean;
  users: User[];
  eigenBedrijf: Bedrijf;
  chosenVestigingsIndex: number;
  isPrinting: boolean;
  koppelBedrijf: Bedrijf;
  @Input() klanten!: Array<Bedrijf>;
  selectedChauffeurs: any;
  selectedWerven: any;
  chauffeurs: User[];
  werven: Werf[];
  connectedBedrijven: Bedrijf[];
  allBedrijven: Bedrijf[];
  originalSelectedChauffeurs: string[];
  originalSelectedWerven: string[];
  isObjectenDelenVisible: boolean;
  selectedBedrijf: any;
  isOvamGebruiker: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private toastrService: NbToastrService,
    public formService: FormService,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() private dialogRef: MatDialogRef<KlantenAddComponent>
  ) {}

  async ngOnInit(){
    this.isLoaded = false;
    this.isPrinting = false;
    while(this.apiService.thisCompany == null){
      await this.delay(50);
    }
    this.company = this.apiService.thisCompany;
    this.isOvamGebruiker = this.apiService.isOvamGebruiker;
    this.isGettingVATnumber = false;
    if(this.users == null){
      this.apiService.getWerfleiders().subscribe(x => {
        this.users = x as User[];
        this.users = this.users.filter(x => x.name !== 'bjorn massoels')
        this.users.sort((a, b) => a.name.localeCompare(b.name));
      });
    }
    this.allBedrijven = this.formService.allBedrijven;
    if(this.allBedrijven == null){
      this.apiService.getBedrijven().subscribe(x => {
        this.allBedrijven = x as Bedrijf[];
        this.connectedBedrijven = this.allBedrijven.filter(x => x.realBedrijf != null);
      });
    } else {
      this.connectedBedrijven = this.allBedrijven.filter(x => x.realBedrijf != null);
    }
    if(this.company.bedrijfsGegevens?.toString() != null && (this.formService.isEigenBedrijf == null || this.formService.isEigenBedrijf)){
      this.apiService.getBedrijf(this.company.bedrijfsGegevens?._id?.toString()).subscribe(x => {
        this.eigenBedrijf = x as Bedrijf;
        this.addForm = this.formBuilder.group({
          naam: this.eigenBedrijf.naam,
          straat: this.eigenBedrijf.straat,
          huisNr: this.eigenBedrijf.huisNr,
          postcode: this.eigenBedrijf.postcode,
          gemeente: this.eigenBedrijf.gemeente,
          land: this.eigenBedrijf.land,
          btwNummer: this.eigenBedrijf.btwNummer,
          ondernemingsNummer: this.eigenBedrijf.ondernemingsNummer,
          email: this.eigenBedrijf.email,
          telefoonNummer:  this.eigenBedrijf.telefoonNummer,
          werfleider: this.eigenBedrijf.werfleider?._id,
          vestigingen:  this.formBuilder.array([]),
        });
        if(this.eigenBedrijf.vestigingen != null && this.eigenBedrijf.vestigingen.length > 0){
          for(let vestiging of this.eigenBedrijf.vestigingen){
            const vestigingen = this.addForm.get('vestigingen') as FormArray;
            vestigingen.push(this.formBuilder.group({
              naam: vestiging.naam,
              vestigingsNummer: vestiging.vestigingsNummer,
              aardVanVerwerking: vestiging.aardVanVerwerking,
              straat: vestiging.straat,
              huisNr: vestiging.huisNr,
              postcode: vestiging.postcode,
              gemeente: vestiging.gemeente,
              land: vestiging.land,
            }));
          }
        }
        this.isSaving = false;
        this.isLoaded = true;
      });
    } else {
      this.addForm = this.formBuilder.group({
        naam: [''],
        straat: [''],
        huisNr: [''],
        postcode: [''],
        gemeente: [''],
        land: [''],
        btwNummer: [''],
        email: [''],
        telefoonNummer: [''],
        werfleider: [''],
        vestigingen:  this.formBuilder.array([]),
      });
      this.addNewVestiging();
      this.addForm.get('naam').valueChanges.pipe(
        startWith(''),
        map(value => value ? value.toString().charAt(0).toUpperCase() + value.toString().slice(1) : '')
      ).subscribe(value => {
        this.addForm.get('naam').setValue(value, { emitEvent: false });
      });
      this.isSaving = false;
      this.isLoaded = true;
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  async onSubmit(data: Bedrijf) {
    if(!this.isSaving) {
      this.isSaving = true;
      this.isNameInvalid = false;
      if(this.formService.isEigenBedrijf != null && !this.formService.isEigenBedrijf){
        if (this.company.bedrijfsGegevens != null) {
          if (this.company.bedrijfsGegevens.btwNummer === data.btwNummer) {
            this.toastrService.warning('U kan uw eigen bedrijf niet toevoegen. Dit kan u instellen bij "Bedrijfsgegevens instellen"', 'Foutieve invoer!', {duration: 4000});
            this.isSaving = false;
            return;
          }
        }
        let checkIfAlreadyExists = this.allBedrijven.find(x => x.btwNummer === data.btwNummer);
        if (checkIfAlreadyExists != null) {
          this.toastrService.warning('Dit bedrijf bestaat al in uw klantenbestand!', 'Foutieve invoer.', {duration: 4000});
          this.isSaving = false;
          return;
        }
      }
      let mayPass = this.checkEmptyFields(data);
      if (mayPass) {
        if (data.btwNummer != null && data.btwNummer !== '' && data.btwNummer !== this.eigenBedrijf?.btwNummer) {
          data.btwNummer = data.btwNummer.replace(/[. \-]+/g, '').trim();
          let countryCode = data.btwNummer.substring(0, 2).toUpperCase();
          if (countryCode === 'BE') {
            data.ondernemingsNummer = data.btwNummer.substring(2);
          } else {
            data.ondernemingsNummer = null;
          }
        }
        if (data.werfleider != null && data.werfleider.toString() === '') {
          data.werfleider = null;
        }

        data.company_id = this.apiService.thisCompany._id as unknown as Company;
        if (this.formService.isEigenBedrijf == null || this.formService.isEigenBedrijf) {
          if (this.company.bedrijfsGegevens != null) {
            data._id = this.company.bedrijfsGegevens?._id.toString();
          }

          this.apiService.createOrUpdateEigenBedrijf(data).subscribe(async x => {
            console.log(x)
            let newBedrijf = x as Bedrijf;
            if (newBedrijf.naam == null) {
              if (x.toString() === 'alreadyExists') {
                this.isSaving = false;
                this.toastrService.warning('Deze onderneming is al geregistreerd bij ons in het systeem. Indien jullie niet meer op dit bedrijf kunnen inloggen, ' +
                  'gelieve even een mail te versturen naar info@selux-tools.com en wij zetten dit recht voor u', 'Onderneming bestaat al bij ons! Attentie!', {duration: 8000});
                return;
              }
            } else {
              if(data.werfleider != null && data.werfleider.toString() !== ''){
                this.eigenBedrijf.werfleider = this.users.find(x => x._id === data.werfleider.toString());
              }
              if (this.apiService.thisCompany.bedrijfsGegevens == null) {
                this.apiService.thisCompany.bedrijfsGegevens = x as Bedrijf;
              }
              if(this.eigenBedrijf?.werfleider){
                this.apiService.thisCompany.bedrijfsGegevens.werfleider = this.eigenBedrijf.werfleider;
              }
              this.isSaving = false;
              this.toastrService.success('Eigen bedrijfsinstellingen zijn succesvol bijgewerkt.', 'Succes!');
            }
          });
        } else {
          //ander bedrijf toevoegen

          if (this.koppelBedrijf != null && this.koppelBedrijf?._id != null) {
            data.realBedrijf = this.koppelBedrijf;
          }
            console.log(data)
            this.apiService.createBedrijf(data).subscribe(async x => {
              console.log(data)
              this.isSaving = false;
              this.formService.latestAddBedrijf = x as Bedrijf;
              this.formService.latestAddBedrijf.realBedrijf = this.koppelBedrijf;
              this.outputEvent.emit('added werf');
              this.toastrService.success(data.naam + ' is aangemaakt', 'Succes!');
              if(this.data?.isOpenFromEdigidCreate){
                this.dialogRef.close(this.formService.latestAddBedrijf);
              }
              this.addForm.reset();
              this.koppelBedrijf = null;
            }, error => {
              this.isSaving = false;
            });
        }
      }
    }
  }
  checkVATnumber() {
    let ondernemingsNummer = this.addForm.get('btwNummer').value;
    ondernemingsNummer = ondernemingsNummer.replace(/[. \-]+/g, '').trim();
    this.addForm.get('btwNummer').setValue(ondernemingsNummer);
    if(!this.formService.isEigenBedrijf){
      if (this.company.bedrijfsGegevens != null) {
        if (this.company.bedrijfsGegevens.btwNummer === ondernemingsNummer) {
          this.toastrService.warning('U kan uw eigen bedrijf niet toevoegen. Dit kan u instellen bij "Bedrijfsgegevens instellen"', 'Foutieve invoer!', {duration: 4000});
          this.isSaving = false;
          return;
        }
      }
      let checkIfAlreadyExists = this.allBedrijven.find(x => x.btwNummer === ondernemingsNummer);
      if (checkIfAlreadyExists != null) {
        this.toastrService.warning('Dit bedrijf bestaat al in uw klantenbestand!', 'Foutieve invoer.', {duration: 4000});
        this.isSaving = false;
        return;
      }
    }
    if(ondernemingsNummer !== ''){
      this.isGettingVATnumber = true;
      this.apiService.checkVATnumber(ondernemingsNummer).subscribe(x => {
        let vatData = x as any;
        console.log(x);
        if(vatData != null && vatData.bedrijfsNaam != null){
          this.addForm.get('naam').setValue(vatData.bedrijfsNaam);
          if(vatData.adres != null){
            this.addForm.get('straat').setValue(vatData.adres.street.nl)
            this.addForm.get('huisNr').setValue(vatData.adres.addressNumber);
            this.addForm.get('gemeente').setValue(vatData.adres.city.nl);
            this.addForm.get('postcode').setValue(vatData.adres.zipcode);
            if(vatData.adres.country?.nl != null && vatData.adres.country.nl !== ''){
              this.addForm.get('land').setValue(vatData.adres.country.nl);
            } else {
              this.addForm.get('land').setValue('België');
            }
          }
          const vestigingenArray = this.addForm.get('vestigingen') as FormArray;
          vestigingenArray.clear();
          if(vatData.vestigingen != null){
            for(let vestiging of vatData.vestigingen){
              vestigingenArray.push(this.formBuilder.group({
                naam: vestiging.city.nl + ' - ' + vestiging.street.nl + ' ' + vestiging.addressNumber,
                vestigingsNummer: vestiging.entityNumberFormatted,
                aardVanVerwerking: undefined,
                straat: vestiging.street.nl,
                huisNr: vestiging.addressNumber,
                postcode: vestiging.zipcode,
                gemeente: vestiging.city.nl,
                land: vestiging.country?.nl == null || vatData.adres.country.nl === '' ? 'België' : vatData.adres.country.nl,
              }));
            }
          }
          this.isGettingVATnumber = false;
        } else {
          this.koppelBedrijf = vatData as Bedrijf;
          if(vatData?.traderName == null || vatData.traderName !== '---'){
            this.addForm.get('naam').setValue(this.koppelBedrijf.gemeente + ' - ' + this.koppelBedrijf.straat + ' ' + this.koppelBedrijf.huisNr);
            this.addForm.get('straat').setValue(this.koppelBedrijf.straat);
            this.addForm.get('huisNr').setValue(this.koppelBedrijf.huisNr);
            this.addForm.get('gemeente').setValue(this.koppelBedrijf.gemeente);
            this.addForm.get('postcode').setValue(this.koppelBedrijf.postcode);
            this.addForm.get('land').setValue(this.koppelBedrijf.land);
            this.addForm.get('email').setValue(this.koppelBedrijf.email);
            this.addForm.get('telefoonNummer').setValue(this.koppelBedrijf.telefoonNummer);
            this.addForm.get('werfleider').setValue(this.koppelBedrijf.werfleider?._id);
            const vestigingenArray = this.addForm.get('vestigingen') as FormArray;
            vestigingenArray.clear();
            for(let vestiging of this.koppelBedrijf.vestigingen){
              vestigingenArray.push(this.formBuilder.group({
                naam: vestiging.naam,
                vestigingsNummer: vestiging.vestigingsNummer,
                aardVanVerwerking: vestiging.aardVanVerwerking,
                straat: vestiging.straat,
                huisNr: vestiging.huisNr,
                postcode: vestiging.postcode,
                gemeente: vestiging.gemeente,
                land: vestiging.land,
              }));
            }
            console.log(this.koppelBedrijf)
            this.isGettingVATnumber = false;
          } else {
            this.koppelBedrijf = null;
            this.isGettingVATnumber = false;
            this.toastrService.warning('BTW nummer is niet geldig of er is iets misgelopen..', 'Oops!');
          }

        }
      }, error => {
        this.isGettingVATnumber = false;
        this.toastrService.warning('BTW nummer is niet geldig of er is iets misgelopen..', 'Oops!');
      });
    }

  }

  checkCountry(shortCountryCode: string) {
    if(shortCountryCode === 'BE'){
      return 'België';
    } else if(shortCountryCode === 'NL'){
      return 'Nederland';
    }
  }
  checkEmptyFields(data) {
    if(data.btwNummer == null || data.btwNummer === ''){
      this.isSaving = false;
      this.toastrService.warning('Gelieve een BTW nummer in te vullen.', 'Oops!');
      return false;
    }
    if(data.btwNummer.length < 10){
      this.isSaving = false;
      this.toastrService.warning('Gelieve een correct BTW nummer in te geven.', 'BTW nummer is niet correct!');
      return;
    }
    if (data.naam == null || data.naam === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een bedrijfsnaam in te vullen.', 'Oops!');
      return false;
    }
    if (data.straat == null || data.straat === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een straat in te vullen.', 'Oops!');
      return false;
    }
    if (data.huisNr == null || data.huisNr === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een huisnummer in te vullen.', 'Oops!');
      return false;
    }
    if (data.postcode == null || data.postcode === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een postcode in te vullen.', 'Oops!');
      return false;
    }
    if (data.gemeente == null || data.gemeente === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een gemeente in te vullen.', 'Oops!');
      return false;
    }
    if (data.land == null || data.land === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een land in te vullen.', 'Oops!');
      return false;
    }
    if((this.formService.isEigenBedrijf == null || this.formService.isEigenBedrijf) &&
      (data.werfleider == null || data.werfleider === '')){
     data.werfleider = null;
    }
    if(this.formService.isEigenBedrijf == null || this.formService.isEigenBedrijf){
      for(let vestiging of data.vestigingen){
        if(vestiging.naam == null || vestiging.naam === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een vestigingsnaam in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.vestigingsNummer == null || vestiging.vestigingsNummer === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een vestigingsnummer in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.straat == null || vestiging.straat === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een straat in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.huisNr == null || vestiging.huisNr === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een huisnummer in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.postcode == null || vestiging.postcode === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een postcode in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.gemeente == null || vestiging.gemeente === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een gemeente in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
        if(vestiging.land == null || vestiging.land === ''){
          this.isSaving = false;
          this.toastrService.warning('Gelieve een land in te vullen voor de vestiging.', 'Oops!');
          return false;
        }
      }
    }
    return true;
  }


  verwijderVestiging(i: number) {
    const vestigingen = this.addForm.get('vestigingen') as FormArray;
    vestigingen.removeAt(i);
  }
  getVestigingen() {
    return this.addForm.get('vestigingen') as FormArray;
  }
  addNewVestiging() {
    const vestigingen = this.addForm.get('vestigingen') as FormArray;
    vestigingen.push(this.formBuilder.group({
      naam: [''],
      vestigingsNummer: [''],
      aardVanVerwerking: [''],
      straat: [''],
      huisNr: [''],
      postcode: [''],
      gemeente: [''],
      land: [''],
    }));
  }

  onCloseClick() {
    if(this.company.hasOnlyAfvalstoffenView || this.formService.lastPage === 'e-digidlist'){
      this.router.navigate(['/pages/e-digidlist'])
    } else {
      this.router.navigate(['/pages/klanten'])
    }
  }

  async downloadQRCode(i: number) {
    this.chosenVestigingsIndex = i;
    this.isPrinting = true;
    await this.delay(50);
    const cleanFilename = (name) => {
      return name.replace(/[\/\\:*?"<>|]/g, '_');
    };
    const options = {
      margin: 10,
      filename: 'QRCode-' +  cleanFilename(this.eigenBedrijf.naam) + '.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    await html2pdf().from(this.qrCodeElement.nativeElement).set(options).save().then(() => {
      this.isPrinting = false;
    });
  }

  shareChauffeur() {
    console.log(this.selectedChauffeurs)
  }

  shareWerf() {
    console.log(this.selectedWerven)
  }

async changeShareCompany() {
      if (this.chauffeurs == null) {
        this.apiService.getChauffeurs().subscribe(x => {
          let chauffeurs = x as User[];
          chauffeurs.sort((a, b) => a.name.localeCompare(b.name));
          this.chauffeurs = chauffeurs;
        });
      }
      if (this.werven == null) {
        this.apiService.getWervenLight().subscribe(x => {
          let werven = x as Werf[];
          werven.sort((a, b) => a.naam.localeCompare(b.naam));
          this.werven = werven;
        });
      }
     while(this.chauffeurs == null || this.werven == null){
        await this.delay(50);
     }
      let connectedChauffeurs = [];
      let connectedWerven = [];
      let selectedCompanyId = this.connectedBedrijven.find(x => x.realBedrijf._id.toString() === this.selectedBedrijf).realBedrijf.company_id.toString();
      for(let chauffeur of this.chauffeurs){
        if(chauffeur.sharedCompanys != null && chauffeur.sharedCompanys.find(x => x.toString() === selectedCompanyId) != null){
          connectedChauffeurs.push(chauffeur._id);
        }
      }
      for(let werf of this.werven){
        if(werf.sharedCompanys != null && werf.sharedCompanys.find(x => x.toString() === selectedCompanyId) != null){
          connectedWerven.push(werf._id);
        }
      }
      this.selectedChauffeurs = connectedChauffeurs;
      this.selectedWerven = connectedWerven;
      this.originalSelectedWerven = connectedWerven;
      this.originalSelectedChauffeurs = connectedChauffeurs;
    console.log(this.werven)
  console.log(this.chauffeurs)
  }

  updateSharedObjectsOfCompany() {
    let sharedChauffeurs = this.selectedChauffeurs as string[];
    let sharedWerven = this.selectedWerven as string[];

    console.log(this.originalSelectedChauffeurs)
    console.log(this.originalSelectedWerven)
    //calculate the difference between the orginal array and the new array and update the shared objects
    let removedChauffeurs = this.originalSelectedChauffeurs.filter(x => !sharedChauffeurs.includes(x));
    let removedWerven = this.originalSelectedWerven.filter(x => !sharedWerven.includes(x));
    let addedChauffeurs = sharedChauffeurs.filter(x => !this.originalSelectedChauffeurs.includes(x));
    let addedWerven = sharedWerven.filter(x => !this.originalSelectedWerven.includes(x));
    let selectedCompanyId = this.connectedBedrijven.find(x => x.realBedrijf._id.toString() === this.selectedBedrijf).realBedrijf.company_id.toString();

    console.log(selectedCompanyId)
    let updateObject = {removedChauffeurs: removedChauffeurs, removedWerven: removedWerven, addedChauffeurs: addedChauffeurs, addedWerven: addedWerven, companyid: selectedCompanyId};
    this.apiService.updateSharedObjectsOfCompany(updateObject).subscribe(x => {
      this.toastrService.success('Gedeelde chauffeurs en werven zijn bijgewerkt.', 'Succes!');
    });
  }

  toggleObjectenDelenVisibility() {
    this.isObjectenDelenVisible = !this.isObjectenDelenVisible;

  }
}
