import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {VasteVerlofdagenBouwService} from "../../services/vasteVerlofdagenBouw.service";
import {BehaviorSubject, forkJoin, mapTo, Observable, of} from "rxjs";
import {map, tap} from "rxjs/operators";
import {Werf} from "../../models/werf";
import {Werfplanning} from "../../models/werfplanning";
import {User} from "../../models/user";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Router} from "@angular/router";

class DayInMonth {
  date: Date;
  day: string;
  isoDate: string;
  isEmpty: boolean;
  soort: string;
}
class Ploeg {
  ploegbaas: User;
  voorkeurArbeiders: User[];
}
@Component({
  selector: 'ngx-werfplanning',
  templateUrl: './werfplanning.component.html',
  styleUrls: ['./werfplanning.component.scss']
})
export class WerfplanningComponent implements OnInit {
  days: string[] = ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'];
  settingsForm: UntypedFormGroup;
  company: Company;
  isLoaded: boolean;
  isSaving: boolean = false;
  isViewingDay: boolean;
  maanden = ["Januari","Februari","Maart","April","Mei","Juni","Juli","Augustus","September", "Oktober","November","December"];
  todayDate: Date;
  jaren: number[] = [];
  currentDate: Date;
  maand: string;
  daysInMonth: BehaviorSubject<DayInMonth[]> = new BehaviorSubject<DayInMonth[]>(null);
  daysInMonth$: Observable<DayInMonth[]> = this.daysInMonth.asObservable();
  lastDayOfMonth: number;
  jaar: string;
  werven: Werf[];
  werfPlanningen: Werfplanning[] = [];
  allUsers: User[];
  allArbeiders: User[];
  isLoadedCount: number;
  assignmentForm: FormGroup;
  selectedDate: Date;
  unassignedArbeiders: User[];
  selectedArbeiders: any[] = [];
  sortChoiceBeschikbareArbeiders: any;
  ploegen: Ploeg[] = [];
  selectedPloegen: Ploeg[] = [];

  constructor(
    private apiService: ApiService,
    private formService: FormService,
    private vasteVerlofDagenBouw: VasteVerlofdagenBouwService, private fb: FormBuilder,
    private cdr: ChangeDetectorRef, private router: Router
  ) {
  }
  async ngOnInit() {
    this.isLoadedCount = 0;
    this.sortChoiceBeschikbareArbeiders = 'naam';
    const users$ = this.allUsers == null ? this.apiService.getUsersByRoleLight('Ploegbaas&Arbeider').pipe(
      tap(x => {
        let allArbeiders = x as User[];
        allArbeiders = allArbeiders.filter(x => x.name !== 'bjorn massoels');
        allArbeiders.sort((a, b) => a.name?.localeCompare(b.name));
        this.allUsers = allArbeiders;
        let allPloegbazen = allArbeiders.filter(x => x.voorkeurArbeiders != null && x.voorkeurArbeiders.length !== 0);
        for(let ploegbaas of allPloegbazen){
          for(let i = 0; i < ploegbaas.voorkeurArbeiders.length; i++){
            let index = allArbeiders.findIndex(x => x._id === ploegbaas.voorkeurArbeiders[i].toString());
            if(index !== -1){
              ploegbaas.voorkeurArbeiders[i] = allArbeiders[index];
            } else {
              ploegbaas.voorkeurArbeiders.splice(i, 1);
              i--;
            }
          }
        }
        allPloegbazen = allPloegbazen.filter(x => x.voorkeurArbeiders.length !== 0);
        this.ploegen = [];
        allPloegbazen.forEach(ploegbaas => {
          this.ploegen.push({ploegbaas: ploegbaas, voorkeurArbeiders: ploegbaas.voorkeurArbeiders});
          allArbeiders = allArbeiders.filter(x => x._id !== ploegbaas._id && !ploegbaas.voorkeurArbeiders.some(m => m._id === x._id));
        })

        allArbeiders = allArbeiders.filter(x => x.functieNaam !== 'Chauffeur');
        this.allArbeiders = allArbeiders;

        this.unassignedArbeiders = allArbeiders;
      }),
      mapTo(1)
    ) : of(1);
    const werven$ = this.werven == null ? this.apiService.getWerven(true,false,true, false).pipe(
      tap(x => {
        this.werven = x as Werf[];
        this.werven  = this.werven.sort((a, b) => a.naam.localeCompare(b.naam));
        for(let werf of this.werven){
          if(!werf.isActiefOpWerfPlanning && werf.uitvoeringsTermijn != null && werf.startDatum != null){
            let startDatum = new Date(werf.startDatum);
            let eindDatum = new Date(werf.startDatum);
            eindDatum.setDate(eindDatum.getDate() + werf.uitvoeringsTermijn);

            let difference = Math.abs(eindDatum.getTime() - startDatum.getTime());
            let days = difference / (1000 * 3600 * 24);
            if(days > werf.uitvoeringsTermijn){
              werf.isActiefOpWerfPlanning = true;
              this.apiService.updateIsWerfActief(werf._id, true).subscribe();
            }
          }
        }
        //shift werven isactief to front of array
        this.werven = this.werven.sort((a, b) => a.isActiefOpWerfPlanning === b.isActiefOpWerfPlanning ? 0 : a.isActiefOpWerfPlanning ? -1 : 1);
      }),
      mapTo(1)
    ) : of(1);


    forkJoin([werven$, users$])
      .pipe(tap(([count1, count2]) => this.isLoadedCount = count1 + count2 ))
      .subscribe();

    while(this.isLoadedCount < 2){
      await this.delay(50);
    }
    this.isLoaded = false;
    this.isSaving = false;
    this.isViewingDay = false;
    this.todayDate = new Date();
    this.currentDate = new Date();
    this.jaren.push(this.todayDate.getFullYear() - 1);
    this.jaren.push(this.todayDate.getFullYear());
    //push de volgende 5 jaren
    for(let i = 1; i < 5; i++){
      this.jaren.push(this.todayDate.getFullYear() + i);
    }
    this.jaar = this.todayDate.getFullYear().toString();
    this.maand = this.maanden[this.currentDate.getMonth()];


    while(this.apiService.thisCompany == null){
      await this.delay(50);
    }
    this.company = this.apiService.thisCompany;
    await this.calculateDaysInMonth(this.currentDate.getMonth(), this.currentDate.getFullYear());


    this.werfPlanningen = this.werven.map(w => {
      const planning = new Werfplanning();
      planning.werf = w;
      planning.selectedArbeiders = [];
      planning.opmerkingAanArbeiders = '';
      return planning;
    });
    this.assignmentForm = this.fb.group({
      arbeiders: [],
      werf: '',
      opmerkingAanArbeiders: ''
    });
    this.isLoaded = true;

  }
  checkJaar($event: any) {
    this.isLoaded = false;
    if($event > this.currentDate.getFullYear()){
      this.maand = this.maanden[0];
      this.currentDate = new Date($event,0 , 1);
    } else if ($event < this.currentDate.getFullYear()) {
      this.maand = this.maanden[11];
      this.currentDate = new Date($event,11 , 1);
    }
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
  }

  checkMaand($event: any) {
    this.isLoaded = false;
    let maandIndex = this.maanden.indexOf($event);
    this.formService.latestMonth = this.currentDate.getMonth();
    this.formService.latestYear = this.currentDate.getFullYear();
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  async calculateDaysInMonth(month: number, year: number) {
    const datesObjects: any[] = [];
    while(this.company == null){
      await this.delay(50);
    }
    let eigenVerlofDagen = this.company.verlofDagen as Array<{fromDate: Date, toDate: Date}>;
    // Get the number of days in the month
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    // Loop through the days of the month and generate Date objects
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      // Reset the time to 0 hour, 0 minute, 0 seconds
      date.setHours(4, 0, 0, 0);
      if(date.getDay() !== 0 && date.getDay() !== 6){
        let soort;
        let isoDate = date.toISOString().split('T')[0];
        if(year === 2023){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2023.find(x =>x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          } else {
            soort = 'werken';
          }
        } else if(year === 2024){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2024.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }  else if(year === 2025){
          if(this.vasteVerlofDagenBouw.rustDagenBouw2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          } else if(this.vasteVerlofDagenBouw.feestDagen2025.find(x => x.toISOString().split('T')[0] === isoDate) != null){
            soort = 'feestdag';
          }  else if(date.getTime() < this.todayDate.getTime()){
            soort = 'vroegereDatum';
          } else if(date.getTime() > this.todayDate.getTime()){
            soort = 'latereDatum';
          }else {
            soort = 'werken';
          }
        }
        if(eigenVerlofDagen != null && eigenVerlofDagen.length !== 0){
          let verlofDag = eigenVerlofDagen.find(x => {
            let fromIso = x.fromDate ? new Date(x.fromDate).toISOString().split('T')[0] : null;
            let toIso = x.toDate ? new Date(x.toDate).toISOString().split('T')[0] : null;

            if (toIso == null) {
              return fromIso === isoDate;
            } else {
              return fromIso <= isoDate && isoDate <= toIso;
            }
          });
          if (verlofDag) {
            soort = 'verlofdag';
          }
        }
        datesObjects.push({date: date, day: this.days[date.getDay()], soort: soort, werkenCount: 0,isoDate: date.toISOString().split('T')[0], werkUren: 0});
      }
    }

    this.lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    //change to friday if the last day is saturday or sunday
    if(new Date(year, month + 1, 0).getDay() === 0){
      this.lastDayOfMonth = this.lastDayOfMonth - 2;
    } else if(new Date(year, month + 1, 0).getDay() === 6){
      this.lastDayOfMonth = this.lastDayOfMonth -1;
    }

    this.daysInMonth.next(datesObjects);
    let tempDaysInMonth = this.daysInMonth.getValue();
    this.daysInMonth$ = this.daysInMonth.pipe(
      map(daysArray => {
        // Calculate the index of the first day of the month
        const firstDayIndex = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), tempDaysInMonth[0].date.getDate()).getDay();

        // Create an array of empty slots with a special flag
        const emptySlots = new Array(Math.max(0, firstDayIndex - 1)).fill({ isEmpty: true });

        // Merge the emptySlots array with the actual daysArray
        return [...emptySlots, ...daysArray];
      })
    );


  }

  bekijk(day: DayInMonth) {
      if(!this.isViewingDay){
        this.formService.selectedIsoDateWerfPlanning = day.isoDate;
        this.selectedDate = day.date;
        this.isViewingDay = true;
      } else {
        this.isViewingDay = false;
        this.formService.selectedIsoDateWerfPlanning = null;
      }
  }




  removePlanning(planning: Werfplanning) {
    const index = this.werfPlanningen.indexOf(planning);
    if (index >= 0) {
      this.werfPlanningen.splice(index, 1);
    }
  }

  getWerfName(werfId: string): string {
    return this.werven.find(w => w._id === werfId)?.naam || 'Onbekende werf';
  }

  getArbeiderNames(arbeiders: User[]): string {
    return arbeiders.map(a => a.name).join(', ');
  }
  convertDate(date: Date){
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + ('0' + date.getFullYear()).slice(-2);
  }

  changeIsWerfActive(werfplanning: Werfplanning) {
    if(!werfplanning.werf.isActiefOpWerfPlanning){
      werfplanning.werf.isActiefOpWerfPlanning = true;
    } else {
      werfplanning.werf.isActiefOpWerfPlanning = false;
    }
    this.apiService.updateIsWerfActief(werfplanning.werf._id, werfplanning.werf.isActiefOpWerfPlanning).subscribe();
    let werfIndex = this.werfPlanningen.findIndex(x => x.werf._id === werfplanning.werf._id);
    this.werfPlanningen[werfIndex].werf.isActiefOpWerfPlanning = werfplanning.werf.isActiefOpWerfPlanning;
    this.werfPlanningen  = this.werfPlanningen.sort((a, b) => a.werf.naam.localeCompare(b.werf.naam));
    this.werfPlanningen = this.werfPlanningen.sort((a, b) => a.werf.isActiefOpWerfPlanning === b.werf.isActiefOpWerfPlanning ? 0 : a.werf.isActiefOpWerfPlanning ? -1 : 1);
  }

  getAllArbeiders(): User[] {
    return [
      ...this.unassignedArbeiders,
      ...this.werfPlanningen.flatMap(wp => wp.selectedArbeiders)
    ];
  }

  isSelected(item: User | Ploeg): boolean {
    if (this.sortChoiceBeschikbareArbeiders === 'ploegen') {
      return this.selectedPloegen.some(selected => selected.ploegbaas._id === (item as Ploeg).ploegbaas._id);
    } else {
      return this.selectedArbeiders.some(selected => selected._id === (item as User)._id);
    }
  }

  goToEditPloegen() {
     this.router.navigate(['/pages/ploegen-instellen']);
  }

  changeSortChoiceBeschikbareArbeiders() {
    console.log(this.sortChoiceBeschikbareArbeiders);
    if(this.sortChoiceBeschikbareArbeiders === 'ploegen'){

    }
  }


  toggleSelection(item: User | Ploeg, event: MouseEvent): void {
    event.preventDefault();
    if (this.sortChoiceBeschikbareArbeiders === 'ploegen') {
      this.togglePloegSelection(item as Ploeg);
    } else {
      this.toggleArbeiderSelection(item as User);
    }
  }

  toggleArbeiderSelection(arbeider: User): void {
    const index = this.selectedArbeiders.findIndex(selected => selected._id === arbeider._id);
    if (index === -1) {
      this.selectedArbeiders.push(arbeider);
    } else {
      this.selectedArbeiders.splice(index, 1);
    }
  }

  togglePloegSelection(ploeg: Ploeg): void {
    const index = this.selectedPloegen.findIndex(selected => selected.ploegbaas._id === ploeg.ploegbaas._id);
    if (index === -1) {
      this.selectedPloegen.push(ploeg);
    } else {
      this.selectedPloegen.splice(index, 1);
    }
  }

  dropArbeider(event: CdkDragDrop<User[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      let itemsToTransfer: User[];

      if (this.selectedArbeiders.length > 0 && this.selectedArbeiders.includes(event.item.data)) {
        itemsToTransfer = [...this.selectedArbeiders];
      } else {
        itemsToTransfer = [event.item.data];
      }

      // Remove items from previous container
      itemsToTransfer.forEach(item => {
        const index = event.previousContainer.data.indexOf(item);
        if (index !== -1) {
          event.previousContainer.data.splice(index, 1);
        }
      });

      // Add items to new container
      event.container.data.splice(event.currentIndex, 0, ...itemsToTransfer);

      // Update werfPlanningen if necessary
      this.updateWerfPlanningAfterArbeiderDrop(event, itemsToTransfer);

      // Clear selection
      this.selectedArbeiders = [];
      this.cdr.detectChanges();
    }
  }

  private updateWerfPlanningAfterArbeiderDrop(event: CdkDragDrop<User[]>, transferredItems: User[]) {
    const sourceWerfplanning = this.werfPlanningen.find(wp => wp.selectedArbeiders === event.previousContainer.data);
    const targetWerfplanning = this.werfPlanningen.find(wp => wp.selectedArbeiders === event.container.data);

    if (sourceWerfplanning) {
      sourceWerfplanning.selectedArbeiders = sourceWerfplanning.selectedArbeiders.filter(
        a => !transferredItems.includes(a)
      );
    }

    if (targetWerfplanning) {
      // Ensure no duplicates
      const uniqueArbeiders = new Set([...targetWerfplanning.selectedArbeiders, ...transferredItems]);
      targetWerfplanning.selectedArbeiders = Array.from(uniqueArbeiders);
    }

    // If dropping to unassigned, remove from all werfplanningen
    if (event.container.data === this.unassignedArbeiders) {
      this.werfPlanningen.forEach(wp => {
        wp.selectedArbeiders = wp.selectedArbeiders.filter(a => !transferredItems.includes(a));
      });
    }

    // Update unassignedArbeiders
    this.unassignedArbeiders = this.allArbeiders.filter(a =>
      !this.werfPlanningen.some(wp => wp.selectedArbeiders.includes(a))
    );
  }

  dropPloeg(event: CdkDragDrop<Ploeg[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const ploegToTransfer = event.item.data as Ploeg;

      if (event.previousContainer.data === this.ploegen) {
        // Ploeg is being assigned to a werf
        this.assignPloegToWerf(ploegToTransfer, event.container.data as unknown as Werfplanning[]);
      } else {
        // Ploeg is being unassigned from a werf
        this.unassignPloegFromWerf(ploegToTransfer);
        this.ploegen.splice(event.currentIndex, 0, ploegToTransfer);
      }

      this.updateUnassignedArbeiders();
      this.cdr.detectChanges();
    }
  }

  private assignPloegToWerf(ploeg: Ploeg, targetWerfplanningen: Werfplanning[]) {
    const targetWerfplanning = targetWerfplanningen[0]; // Assuming we're dropping into a single werfplanning
    if (targetWerfplanning) {
      // Add all voorkeurArbeiders to the werfplanning
      targetWerfplanning.selectedArbeiders = [
        ...targetWerfplanning.selectedArbeiders,
        ...ploeg.voorkeurArbeiders
      ];

      // Remove duplicates
      targetWerfplanning.selectedArbeiders = Array.from(new Set(targetWerfplanning.selectedArbeiders));

      // Remove the ploeg from the available ploegen
      const index = this.ploegen.findIndex(p => p.ploegbaas._id === ploeg.ploegbaas._id);
      if (index !== -1) {
        this.ploegen.splice(index, 1);
      }
    }
  }

  private unassignPloegFromWerf(ploeg: Ploeg) {
    for (const werfplanning of this.werfPlanningen) {
      werfplanning.selectedArbeiders = werfplanning.selectedArbeiders.filter(
        arbeider => !ploeg.voorkeurArbeiders.some(pa => pa._id === arbeider._id)
      );
    }
  }

  private updateUnassignedArbeiders() {
    const assignedArbeiders = new Set(this.werfPlanningen.flatMap(wp => wp.selectedArbeiders.map(a => a._id)));
    this.unassignedArbeiders = this.allArbeiders.filter(a => !assignedArbeiders.has(a._id));
  }


  drop($event) {
     if(this.sortChoiceBeschikbareArbeiders === 'ploegen'){
       this.dropPloeg($event);
     } else {
        this.dropArbeider($event);
     }
  }
}
