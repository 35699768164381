import {Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, UntypedFormBuilder} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import {Werf} from "../../../models/werf";
import {ApiService} from "../../../services/api.service";
import {FormService} from "../../../services/form.service";
import {Company} from "../../../models/company";
import {HasChangedPopupComponent} from "../../has-changed-popup/has-changed-popup.component";
import {User} from "../../../models/user";
import {map, startWith} from "rxjs/operators";
import {Bedrijf} from "../../../models/E-digid/bedrijf";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";




@Component({
  selector: 'ngx-klanten-edit',
  templateUrl: './klanten-edit.component.html',
  styleUrls: ['./klanten-edit.component.scss', '../../../styles/edit-form.scss']
})
export class KlantenEditComponent implements OnInit, OnDestroy {
  @ViewChild('qrCodeElement') qrCodeElement: ElementRef;
  private _id: string;
  public isLoaded: boolean;
  klanten: Bedrijf[];
  klant: Bedrijf;
  index: number;
  isNameInvalid: boolean = false;
  editForm;
  company: Company;
  hasChangedValue: boolean = false;
  isSaving: boolean;
  companyId: string;
  isPrinting: boolean;
  verwerkingstechnieken = this.formService.verwerkingstechnieken;
  D_en_R_codes = this.formService.D_en_R_codes;
  chosenVestigingsIndex: number;
  isRealBedrijfView: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: NbToastrService,
    private dialog: MatDialog,
    private formService: FormService
  ) {
    route.params.subscribe((val) => {
      this.loadData();
    });
  }

  ngOnInit(){

  }

  async loadData() {
    this.isLoaded = false;
    this.isPrinting = false;
    this._id = this.route.snapshot.paramMap.get('id');
    this.hasChangedValue = false;
    this.klanten = this.formService.allBedrijven;
    if(this.klanten == null){
      this.apiService.getBedrijven().subscribe(x => {
        this.klanten = x as Bedrijf[];
        this.sortByName();
        this.formService.allBedrijven = this.klanten;
        this.klant = this.klanten.find(x => x._id === this._id);
        this.index = this.klanten.findIndex(x => x._id === this._id);
        this.buildForm();
      });
    } else {
      this.klant = this.klanten.find(x => x._id === this._id);
      this.index = this.klanten.findIndex(x => x._id === this._id);
      this.buildForm();
    }

   while(this.apiService.thisCompany == null){
      await this.delay(50);
   }
    this.company = this.apiService.thisCompany;
    this.companyId = this.company._id;
  }
  toastBadForm() {
    this.toastrService.warning('Probeer het opnieuw', 'Oops!');
  }
  async buildForm(){

    let bedrijf = this.klant;
    if(this.klant.realBedrijf != null){
      this.isRealBedrijfView = true;
      bedrijf = this.klant.realBedrijf;
    } else {
      this.isRealBedrijfView = false;
    }
    this.editForm = this.formBuilder.group({
      naam: bedrijf.naam,
      straat: bedrijf.straat,
      huisNr: bedrijf.huisNr,
      postcode: bedrijf.postcode,
      gemeente: bedrijf.gemeente,
      land: bedrijf.land,
      btwNummer: bedrijf.btwNummer,
      ondernemingsNummer: bedrijf.ondernemingsNummer,
      email: bedrijf.email,
      telefoonNummer:  bedrijf.telefoonNummer,
      vestigingen:  this.formBuilder.array([]),
    });
    if(bedrijf.vestigingen != null && bedrijf.vestigingen.length > 0){
      for(let vestiging of bedrijf.vestigingen){
        const vestigingen = this.editForm.get('vestigingen') as FormArray;
        vestigingen.push(this.formBuilder.group({
          naam: vestiging.naam,
          vestigingsNummer: vestiging.vestigingsNummer,
          aardVanVerwerking: vestiging.aardVanVerwerking,
          straat: vestiging.straat,
          huisNr: vestiging.huisNr,
          postcode: vestiging.postcode,
          gemeente: vestiging.gemeente,
          land: vestiging.land,
        }));
      }
    }
    this.editForm.valueChanges.subscribe(x => {
      this.hasChangedValue = true;
    })
    this.editForm.get('naam').valueChanges.pipe(
      startWith(this.editForm.get('naam').value),
      map(value => value ? value.toString().charAt(0).toUpperCase() + value.toString().slice(1) : '')
    ).subscribe(value => {
      this.editForm.get('naam').setValue(value, { emitEvent: false });
    });

    this.isLoaded = true;
  }

  async onSubmit(data) {
    this.isNameInvalid = false;
    if(!this.isSaving){
      this.isSaving = true;

      data._id = this._id;
      let mayPass = this.checkEmptyFields(data);
      if(mayPass){
        if(data.btwNummer != null && data.btwNummer !== '' && data.btwNummer !== this.klant.btwNummer){
          let countryCode = data.btwNummer.substring(0, 2).toUpperCase();
          if(countryCode === 'BE'){
            data.ondernemingsNummer = data.btwNummer.substring(2);
          } else {
            data.ondernemingsNummer = null;
          }
        }
        data.company_id = this.companyId;

        this.apiService.updateBedrijf(data).subscribe(x => {
          this.hasChangedValue = false;
          this.isSaving = false;
          this.klanten[this.index] = x as Bedrijf;
          this.formService.allBedrijven = this.klanten;
          this.toastrService.success(data.naam + ' is gewijzigd', 'Succes!');
        })
      }
    }
  }
  ngOnDestroy(){
    this.formService.previousIndexScroll = this.index;
    this.formService.previousPageForScrollIndex = 'klanten';
  }
  onCloseClick(isDelete: boolean) {
    this.formService.previousIndexScroll = this.index;
    this.formService.previousPageForScrollIndex = 'klanten';
    if(!isDelete){
      this.checkChangedValue('/pages/klanten');
    } else {
      this.router.navigate(['/pages/klanten']);
    }
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
  onDeleteClick() {
    const dialogRef = this.dialog.open(DeleteDialogKlant, {
      width:'470px',
      panelClass: 'mat-dialog-padding'
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(result){
        this.klant._id = this._id;
        this.apiService.deleteBedrijf(this.klant).subscribe(x => {
          this.klanten.splice(this.index, 1);
          this.index--;
          this.toastrService.danger(this.klant.naam + ' is verwijderd', 'Succes!');
          if(this.klanten.length === 0 ){
            this.onCloseClick(true);
          } else if(this.index + 1 !== this.klanten.length){
            this.onNextClick(true);
          } else {
            this.index++;
            this.onPreviousClick(true)
          }
        });
      }
    });

  }

  onNextClick(isDelete: boolean) {
    if(!isDelete){
      this.checkChangedValue('/pages/editklant/' + this.klanten[this.index + 1]._id);
    } else {
      this.router.navigate(['/pages/editklant/' + this.klanten[this.index + 1]._id]);
    }
  }

  checkChangedValue(route: string){
    if(this.hasChangedValue){
      this.formService.previousRoute = route;
      const dialogRef = this.dialog.open(HasChangedPopupComponent, {
        width:'450px',
        height:'200px',
        panelClass: 'mat-dialog-padding'
      });
    } else {
      this.router.navigate([route]);
    }
  }
  onPreviousClick(isDelete: boolean) {
    if(!isDelete){
      this.checkChangedValue('/pages/editklant/' + this.klanten[this.index - 1]._id);
    } else {
      this.router.navigate(['/pages/editklant/' + this.klanten[this.index - 1]._id]);
    }
  }

  sortByName() {
      this.klanten.sort((a,b) => a.naam.toLowerCase().localeCompare(b.naam.toLowerCase()));
  }
  checkEmptyFields(data) {
    if(data.btwNummer == null || data.btwNummer === ''){
      this.isSaving = false;
      this.toastrService.warning('Gelieve een BTW nummer in te vullen.', 'Oops!');
      return false;
    }
    if (data.naam == null || data.naam === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een bedrijfsnaam in te vullen.', 'Oops!');
      return false;
    }
    if (data.straat == null || data.straat === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een straat in te vullen.', 'Oops!');
      return false;
    }
    if (data.huisNr == null || data.huisNr === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een huisnummer in te vullen.', 'Oops!');
      return false;
    }
    if (data.postcode == null || data.postcode === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een postcode in te vullen.', 'Oops!');
      return false;
    }
    if (data.gemeente == null || data.gemeente === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een gemeente in te vullen.', 'Oops!');
      return false;
    }
    if (data.land == null || data.land === '') {
      this.isSaving = false;
      this.toastrService.warning('Gelieve een land in te vullen.', 'Oops!');
      return false;
    }

    return true;
  }


  verwijderVestiging(i: number) {
    const vestigingen = this.editForm.get('vestigingen') as FormArray;
    vestigingen.removeAt(i);
  }
  getVestigingen() {
    return this.editForm.get('vestigingen') as FormArray;
  }
  addNewVestiging() {
    const vestigingen = this.editForm.get('vestigingen') as FormArray;
    vestigingen.push(this.formBuilder.group({
      naam: [''],
      vestigingsNummer: [''],
      aardVanVerwerking: [''],
      straat: [''],
      huisNr: [''],
      postcode: [''],
      gemeente: [''],
      land: [''],
    }));
  }
}

@Component({
  selector: 'delete-dialog-klant',
  templateUrl: 'delete-dialog.html',
})
export class DeleteDialogKlant{
  constructor(
    public dialogRef: MatDialogRef<DeleteDialogKlant>,
    public formService: FormService
  ) {
    this.formService.isDelete = false;
  }

  onNoClick(): void {
    this.formService.isDelete = false;
    this.dialogRef.close();
  }

  onDeleteClick() {
    this.formService.isDelete = true;
    this.dialogRef.close();
  }
}
