<div class="buttonRow">
  <h1 class="custom-title">Eigen verlofdagen instellen</h1>
  <button mat-raised-button class="nav-button-black" (click)="goBack()">Sluiten</button>


  <button  (click)="opslaan()" mat-raised-button class="save-button" color="primary" type="submit">
    Opslaan
  </button>
</div>
<nb-card style="max-width:1000px;"  class="scroll-table2">
  <h3 style="margin-left: 35px;font-size: 20px;font-weight: bold;margin-bottom: 3px;text-decoration: underline">Verlofdagen</h3>
  <form *ngIf="isLoaded" [formGroup]="form" class="form-container">
    <button style="margin-top: 10px;margin-left: 10px;" class="btn btn-add" (click)="voegVerlofdagToe()">
      Voeg Datum of Periode toe <i style="margin-left:5px" class="fas fa-plus-circle"></i>
    </button>
    <div formArrayName="verlofdagen" class="verlofdagen-container">
      <div *ngFor="let verlofdag of verlofdagen().controls; let i = index">
        <div [formGroupName]="i" class="verlofdag-item">
          <input class="selectBox" formControlName="datum" nbInput placeholder="Kies een datum.." [nbDatepicker]="datePicker">
          <nb-rangepicker #datePicker></nb-rangepicker>
          <button  mat-raised-button class="delete-button" color="warn" type="button"
                  (click)="verwijderVerlofdag(i)"><i style="margin-left:1px !important;" class="fa-solid fa-trash-can"></i></button>
          <span *ngIf="form.get('verlofdagen').get(i.toString()).get('datum').value?.start != null && form.get('verlofdagen').get(i.toString()).get('datum').value?.start !== ''" class="datumText">
  {{ getDutchDayName(form.get('verlofdagen').get(i.toString()).get('datum').value?.start) }} {{ (form.get('verlofdagen').get(i.toString()).get('datum').value?.start) | date:'shortDate' }}
            {{form.get('verlofdagen').get(i.toString()).get('datum').value?.end != null && form.get('verlofdagen').get(i.toString()).get('datum').value?.end !== '' ? ' tot ': ''}}
            {{ getDutchDayName(form.get('verlofdagen').get(i.toString()).get('datum').value?.end) }} {{ (form.get('verlofdagen').get(i.toString()).get('datum').value?.end) | date:'shortDate' }}
</span>
        </div>
      </div>
    </div>
  </form>


</nb-card>
