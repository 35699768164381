<div class="update-container">
  <p class="update-header">Laatste updates:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-file-excel"></i> Verbeterde excel met totalen en kostenberekening</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-users-line"></i><b>Visueel ploegen instellen bij tab 'Personeel'</b></p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-cloud-sun"></i><b>Verbeterde bepaling weerverlet dagboek der werken & verfijningen</b></p>
  <div class="version">Laatste versie mobiele app: V23.3  -  07/10/1024</div>
</div>
<div class="update-container">
  <p class="update-header">Aankomende functies:</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-circle-nodes"></i>Koppeling met API's van Grondbank & Grondwijzer in de transportmodule</p>
  <p class="update-item"><i style="padding-right: 4px" class="fa fa-bell"></i>Werfplanning en personeelstoewijzing met notificaties</p>
  <p class="update-item"><i style="padding-right: 3px" class="fa fa-sync-alt"></i><b>Doorlopende optimalisatie van alle modules</b></p>
</div>
