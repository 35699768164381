import {Werf} from "./werf";
import {User} from "./user";


export class Werfplanning {
  public werf: Werf;
  public _id : string;
  selectedArbeiders: User[];
  selectedPloegen: User[];
  verlofArbeiders: User[];
  opmerkingAanArbeiders: string;
  constructor() {
  }
}
