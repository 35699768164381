import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import {NbDatepicker, NbToastrService} from "@nebular/theme";
import {ApiService} from "../../services/api.service";
import {FormService} from "../../services/form.service";
import {Company} from "../../models/company";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import moment from "moment";

@Component({
  selector: 'verlofdagen-instellingen',
  templateUrl: './verlofdagen-instellingen.component.html',
  styleUrls: ['./verlofdagen-instellingen.component.scss', '../../styles/item-table.scss']
})
export class VerlofdagenInstellingenComponent implements OnInit {
  form: FormGroup;
  company: Company;
  isLoaded: boolean;
  constructor(private fb: FormBuilder, private formService: FormService,
              private apiService: ApiService, private router: Router,
              private toastController: NbToastrService) {

  }
  async ngOnInit() {
    this.isLoaded = false;
    this.company = this.apiService.thisCompany;
    while(this.company == null){
      await this.delay(50);
      this.company = this.apiService.thisCompany;
    }
    this.buildForm();
  }

  buildForm() {
    let today = moment();
    if(this.company._id == null) this.company._id = this.company.id;
    this.form = this.fb.group({
      verlofdagen: this.fb.array([]),
    });
    if(this.company.verlofDagen != null && this.company.verlofDagen.length > 0){
      this.company.verlofDagen.forEach(x => {
        x.fromDate = new Date(x.fromDate);
        x.toDate = x.toDate != null ? new Date(x.toDate) : undefined;
      });
      this.company.verlofDagen.sort((a, b) =>  b.fromDate.getTime() - a.fromDate.getTime() );

      this.company.verlofDagen.forEach(x => {
        const fromDate = moment(x.fromDate);
        const toDate = moment(x.toDate);

        const monthsDifference = today.diff(toDate, 'months', true);

        if (monthsDifference <= 2) {
          this.verlofdagen().push(
            this.fb.group({
              datum: [{start: fromDate, end: x.toDate != null ? toDate : undefined}],
            })
          );
        }

      });
    } else {
      this.verlofdagen().push(
        this.fb.group({
          datum: [],
        })
      );
    }

    this.isLoaded = true;
  }
  verlofdagen(): FormArray {
    return this.form.get('verlofdagen') as FormArray;
  }

  voegVerlofdagToe() {
    let verlofDagen = this.verlofdagen();
     verlofDagen.insert(0,  this.fb.group({
       datum: [],
     }));
  }

  verwijderVerlofdag(index: number) {
    this.verlofdagen().removeAt(index);
  }

  opslaan() {
    let startAndEndDates = [];
    this.form.value.verlofdagen.forEach(x => {
      if (x.datum?.start != null) {
        let startDate = new Date(x.datum.start.toDate());
        startDate.setHours(4);

        if (x.datum.end == null || x.datum.start.toString() === x.datum.end.toString()) {
          startAndEndDates.push({ fromDate: startDate, toDate: undefined });
        } else {
          let endDate = new Date(x.datum.end.toDate());
          endDate.setHours(4);
          startAndEndDates.push({ fromDate: startDate, toDate: endDate });
        }
      }
    });
    this.company.verlofDagen = startAndEndDates as any;
    this.apiService.updateVerlofdagen(this.company.verlofDagen).subscribe(x => {
      this.toastController.success('Verlofdagen opgeslagen', 'Success');
    });
  }
  getDutchDayName(date: Date): string {
    const dayNames = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    return dayNames[new Date(date).getDay()];
  }

  goBack() {
    this.router.navigate(['/pages/settings'])
  }
  delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }
}
